import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
/* eslint-disable */
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "order",
    meta: { title: "Order Now | WordMight.Com" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/order/order.vue"),
  },
  {
    path: "/order/:service/:project/:wpp/:page",
    name: "orderWihtOption",
    meta: { title: "Order Now | WordMight.Com" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/order/order.vue"),
  },
  {
    path: "/order/:orderId/:email",
    name: "orderEdit",
    meta: { title: "Order Now | WordMight.Com" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/order/order.vue"),
  },
  {
    path: "/order-preview",
    name: "orderPreview",
    meta: { title: "Preview you order | WordMight.Com" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/previeworder/previeworder.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
