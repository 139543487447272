
/* eslint-disable */
import { defineComponent } from 'vue';
import PageLoader from "./components/PageLoader/pageloader.vue";

export default defineComponent({
    name: 'App',
    components: {
        PageLoader,
    },
});
