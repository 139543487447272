import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'

const app = createApp(App).use(ElementPlus).use(store).use(router)

// Set up the HTTP interceptor
// axios.interceptors.request.use(
//   (config) => {
//     // Show page loader when API call starts
//     app.config.globalProperties.$loading.value = true;
//     console.log(app.config.globalProperties.$loading.value);
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// axios.interceptors.response.use(
//   (response) => {
//     // Hide page loader when API call completes
//     app.config.globalProperties.$loading.value = false;
//     return response;
//   },
//   (error) => {
//     // Hide page loader when API call fails
//     app.config.globalProperties.$loading.value = false;
//     return Promise.reject(error);
//   }
// );

app.mount('#app')
