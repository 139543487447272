/* eslint-disable */
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "PageLoader",
  setup() {
    const loading = ref(false);
    const progress = ref(0);

    return {
      loading,
      progress,
    };
  },
});
